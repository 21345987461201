// @flow
import React from 'react'
import classnames from 'classnames'
import { withStyles, AppBar, IconButton, Toolbar, Typography, Tooltip } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import CorporationIcon from 'components/atoms/corporationIcon'
import ExternalLink from 'components/atoms/externalLink'
import FeatureNotification from './featureNotification'

import {
  ENV_BANNER_ENABLED,
  HELP_URL,
  FEATURE_RESOURCE_URL
} from 'qap/constants'
import QAPVars from 'qap/vars'

import CurrentUserMenu from './currentUserMenu'

type Props = {
  classes: Object,
  onDrawerToggle: () => void,
  openSidebar: boolean
}

const noPrint = {
  '@media print': {
    display: 'none'
  }
}

const styles = theme => ({
  root: {
    top: ENV_BANNER_ENABLED ? QAPVars.envBannerHeight : 0,
    '@media print': {
      position: 'static',
      boxShadow: 'none'
    }
  },
  corporationIcon: {
    height: '3.4em',
    width: 200,
    paddingLeft: theme.spacing(2),
    '@media print': {
      paddingLeft: 0
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(3.5)
    }
  },
  flex: {
    flex: 1
  },
  navIcon: {
    ...noPrint
  },
  navIconHide: {
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  helpLink: {
    paddingRight: theme.spacing(6),
    textDecoration: 'none',
    ...noPrint
  },
  notificationContainer: {
    marginRight: theme.spacing(3)
  },
  appBarShiftOpen: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: QAPVars.sideNavBarWidth,
      width: `calc(100% - ${QAPVars.sideNavBarWidth})`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    }
  },
  appBarShiftClose: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: QAPVars.sideNavBarWidth,
      width: '100%',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    }
  },
  toolbar: {
    paddingLeft: theme.spacing(1)
  },
  collapsedIconPadding: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(1.375)
    }
  }
})

const NavigationBar = ({ classes, openSidebar, onDrawerToggle }: Props) => {
  const currentUserMenuRef = React.createRef()

  return (
    <AppBar className={classnames(classes.root, { [classes.appBarShiftOpen]: openSidebar, [classes.appBarShiftClose]: !openSidebar })}>
      <Toolbar className={classes.toolbar}>
        <Tooltip title={openSidebar ? '' : 'Expand the sidebar'}>
          <IconButton
            onClick={onDrawerToggle}
            className={classnames(classes.navIcon, { [classes.navIconHide]: openSidebar })}
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
        <div className={classes.flex}>
          <CorporationIcon className={classnames(classes.corporationIcon, {[classes.collapsedIconPadding]: openSidebar})} />
        </div>

        <ExternalLink to={HELP_URL} className={classes.helpLink}>
          <Typography variant='subtitle1'>Need help?</Typography>
        </ExternalLink>

        { FEATURE_RESOURCE_URL && <FeatureNotification styles={classes} /> }

        <CurrentUserMenu menuRef={currentUserMenuRef} />
      </Toolbar>
    </AppBar>
  )
}

export default withStyles(styles)(NavigationBar)
