// @flow
import React, { Fragment } from 'react'
import { withStyles, Button, IconButton, SnackbarContent } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { stepsStatusHash } from 'utilities/stepsStatusHash'

import QAPVars from 'qap/vars'
import MyQAP from '../../atoms/corporationIcon'

type Props = {
  classes: Object,
  feature: string,
  featureTours: Object,
  message: string,
  onStart: () => void,
  steps: Array<Object>,
  updateFeatureTour: (id: string, data: Object) => void
}

const styles = theme => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    maxWidth: 'none',
    backgroundColor: QAPVars.teal,
    zIndex: theme.zIndex.tooltip,
    borderRadius: 0
  },
  message: {
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    maxWidth: 900,
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    letterSpacing: 0.6,
    paddingTop: 14,
    paddingBottom: 14,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  button: {
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    minWidth: 130,
    height: 'auto',
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1)
    }
  },
  logo: {
    width: '200px',
    height: theme.spacing(5),
    marginRight: '40px'
  }
})

const TourAlert = ({ classes, feature, featureTours, message, onStart, updateFeatureTour, steps }: Props) => {
  const handleClose = () => {
    updateFeatureTour(feature, { status: stepsStatusHash({ newStatus: 'dismissed', steps }) })
  }

  return (
    <SnackbarContent
      classes={{ root: classes.root, message: classes.message }}
      message={
        <Fragment>
          <MyQAP className={classes.logo} reverse />
          <span>{message}</span>
          <Button
            className={classes.button}
            variant='contained'
            onClick={onStart}
          >
            Start tour
          </Button>
        </Fragment>
      }
      action={
        <IconButton
          key='close'
          aria-label='Close'
          color='inherit'
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      }
    />
  )
}

export default withStyles(styles)(TourAlert)
