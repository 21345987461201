// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  withStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@material-ui/core'

import { requestActions } from 'state/request'

import type { ApplicationError, RequestError } from 'types'
import { APPLICATION_ERRORS } from 'qap/constants'

type RateLimitError = {
  retryAfter: number
}

type Props = {
  errorCode: ?string,
  title: ?string,
  message: ?string,
  rateLimitError: ?RateLimitError,
  classes: Object,
  noBackdrop?: boolean,
  clearRequestErrors: Function,
  onClose?: Function
}

type DefaultAction = {
  action: { label: string, path?: string }
}

const styles = theme => ({
  title: {
    fontFamily: theme.typography.caption.fontFamily
  },
  noBackdrop: {
    display: 'none'
  }
})

const DEFAULT_ACTION: DefaultAction = { action: { label: 'OK' } }

class ErrorDialog extends Component <Props> {
  handleClose = (event: Object, reason: string) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown'){
      return
    }

    const { clearRequestErrors, onClose } = this.props
    clearRequestErrors()
    onClose && onClose()
  }

  errorMessage = (error: ApplicationError): string => {
    let msg = error.message

    const { rateLimitError } = this.props
    if (rateLimitError) {
      const { retryAfter } = rateLimitError
      msg = msg.replace('{retryAfter}', retryAfter.toString())
    }

    return msg
  }

  actions = (error: ApplicationError | DefaultAction) => {
    const { action: { label, path } } = error
    let btnAction = {}

    if (path) {
      btnAction['href'] = path
    } else {
      btnAction['onClick'] = this.handleClose
    }

    return (
      <DialogActions>
        <Button variant='contained' color='primary' {...btnAction}>
          { label }
        </Button>
      </DialogActions>
    )
  }

  render () {
    const { errorCode, noBackdrop, title, message, classes } = this.props
    if (!errorCode) return null
    const error = APPLICATION_ERRORS[errorCode]
    if (!error && !title && !message) return null

    let dialogProps = {
      open: true,
      fullWidth: true,
      onClose: this.handleClose
    }

    if (noBackdrop) {
      dialogProps = {
        ...dialogProps,
        'BackdropProps': {
          classes: {
            root: classes.noBackdrop
          }
        }
      }
    }

    return (
      <Dialog {...dialogProps}>
        <DialogTitle disableTypography>
          <Typography variant='h6' className={classes.title}>
            { title || error.title }
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message || this.errorMessage(error) }
          </DialogContentText>
        </DialogContent>
        { this.actions(error || DEFAULT_ACTION) }
      </Dialog>
    )
  }
}

const rateLimitError = (e: RequestError): ?RateLimitError => {
  if (!Array.isArray(e.errors)) return
  const error = e.errors.find(err => err.status === '429')
  return error && error.meta
}

const mapStateToProps = (state, _) => {
  const {
    request: { errors }
  } = state
  if (errors && errors.length) {
    const error = errors[0]
    const { status: errorCode, title, message, onClose } = error

    return {
      errorCode,
      title,
      message,
      onClose,
      rateLimitError: rateLimitError(error)
    }
  }
  return {}
}

const mapDispatchToProps = {
  clearRequestErrors: requestActions.clearRequestErrors
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ErrorDialog)
)
