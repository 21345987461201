// @flow
import React from 'react'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'

type ModalIconProps = {
  notificationType: string,
  className: string
}

export const GlobalNotificationModalIcon = ({ notificationType, className }: ModalIconProps) => {
  if (notificationType === 'error') return <ErrorOutlineIcon className={className} />
  if (notificationType === 'warning') return <ReportProblemOutlinedIcon className={className} />
  if (notificationType === 'info') return <InfoOutlinedIcon className={className} />
  if (notificationType === 'success') return <CheckCircleOutlinedIcon className={className} />

  return null
}
