import React, { Fragment, useState, useContext } from 'react'
import { withStyles, Button, Dialog, DialogContent, DialogActions } from '@material-ui/core'

import { AuthContext } from 'contexts/auth'
import SwitchOrgranisationInput from './input'
import { setLocalStorageOrganisationIds } from './helpers'

const styles = theme => ({
  paperFullWidth: {
    overflowY: 'visible'
  },
  dialogContentRoot: {
    overflowY: 'visible'
  }
})

type Props = {
  onClose: () => void,
  submitting: boolean,
  classes: Object
}

const SwitchOrganisation = ({ onClose, submitting = false, classes }: Props) => {
  const [organisationIds, setOrganistationIds] = useState()
  const { fetchSessionInfo } = useContext(AuthContext)

  const handleSubmit = () => {
    setLocalStorageOrganisationIds(organisationIds)
    fetchSessionInfo()
    onClose()
  }

  const onDialogClose = (event: Object, reason: string) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown'){
      onClose()
    }
  }

  return (
    <Dialog
      open
      fullWidth
      maxWidth='lg'
      onClose={onDialogClose}
      classes={{ paperFullWidth: classes.paperFullWidth }}
    >
      <Fragment>
        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          <SwitchOrgranisationInput setOrganistationIds={setOrganistationIds} />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={onClose}
            disabled={submitting}
            variant='outlined'
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={submitting}
            type='submit'
            onClick={handleSubmit}
          >
            Save
          </Button>
        </DialogActions>
      </Fragment>
    </Dialog>
  )
}

export default withStyles(styles)(SwitchOrganisation)
