// @flow
import React from 'react'
import {
  CircularProgress,
  Typography
} from '@material-ui/core'

export type Props = {
  show?: boolean,
  size?: number,
  text?: string,
  classes?: Object
}

const ProgressIndicator = (props: Props) => {
  const { show = false, size = 24, text = '', classes = {}, ...others } = props

  return (
    show ? (
      <div className={classes.progressIndicatorContainer}>
        <CircularProgress size={size} {...others} />
        {
          Boolean(text) &&
          <Typography className={classes.progressIndicatorText}>{text}</Typography>
        }
      </div>
    ) : null
  )
}

export default ProgressIndicator
