// @flow
import React from 'react'
// $FlowFixMe
import LoadingBar from 'react-redux-loading-bar'
import { withStyles } from '@material-ui/core'

import QAPVars from 'qap/vars'

type Props = {
  classes: Object
}

const styles = {
  root: {
    zIndex: 9999,
    position: 'fixed',
    backgroundColor: QAPVars.pRDark,
    height: 3
  }
}

const Loading = ({ classes }: Props) => (
  <LoadingBar className={classes.root} showFastActions />
)

export default withStyles(styles)(Loading)
