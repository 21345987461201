// @flow
import React, { useContext, useState, useEffect, useCallback, useRef } from 'react'
import debounce from 'lodash/debounce'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { AuthContext } from 'contexts/auth'

import { organisationsActions } from 'state/organisations'
import { ListboxComponent } from 'utilities/autocompleteListInput/helpers'

import type { Organisation } from 'types'

type Props = {
  fetchOrganisations: (query: string) => void,
  setOrganistationIds: (values: string[]) => void,
  organisations: Organisation[]
}

const SwitchOrgranisationInput = (props: Props) => {
  const { selectedOrganisations = [] } = useContext(AuthContext)
  const {
    organisations = [],
    fetchOrganisations,
    setOrganistationIds
  } = props

  const [inputValue, setInputValue] = useState('')

  selectedOrganisations.forEach(org => {
    if (!organisations.find(({ id }) => id === org.id)) organisations.push(org)
  })

  const options = organisations.map(({ id, name }) => ({ key: id, label: name, value: id }))
  const selectedOptions = selectedOrganisations.map(({ id }) => options.find(({ key }) => key === id))

  const onChange = (_, options) => {
    const values = (options || []).map(({ value }) => value)
    setOrganistationIds(values)
  }

  const handleInputChange = (_, newValue: string) => {
    setInputValue(newValue)
  }

  // eslint-disable-next-line
  const filterOptions = useRef(useCallback(debounce((inputValue) =>
    fetchOrganisations(inputValue), 500, { leading: true, maxWait: 1500 })))

  useEffect(() => {
    filterOptions.current(inputValue)
  }, [inputValue])

  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      getOptionLabel={({ label }) => label || ''}
      options={[...selectedOptions, ...options]}
      ListboxComponent={ListboxComponent}
      renderInput={
        params =>
          <TextField
            fullWidth
            {...params}
            label='Switch Organisations'
            variant='outlined'
          />
      }
      getOptionSelected={(option, value) => option.key === value.key}
      defaultValue={selectedOptions}
      onInputChange={handleInputChange}
      onChange={onChange}
    />
  )
}

const mapStateToProps = (state) => ({
  organisations: state.organisations.data
})

const mapDispatchToProps = {
  fetchOrganisations: organisationsActions.fetchOrganisations
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchOrgranisationInput)
