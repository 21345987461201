// @flow
import React from 'react'
import { withStyles } from '@material-ui/core'
import ProgressIndicator from 'components/molecules/progressIndicator'
import QAPVars from 'qap/vars'

type Props = {
  classes: Object,
  children?: string
}

const styles = (theme) => ({
  loading: {
    color: QAPVars.pGLight,
    fontWeight: 'normal',
    margin: theme.spacing(2, 0),
    display: 'inline-flex'
  },
  progressIndicator: {
    marginLeft: 8
  }
})

const LoadingMessage = ({ classes, children }: Props) => (
  <h4 className={classes.loading}>
    {children || 'Loading'}
    <ProgressIndicator size={20} show className={classes.progressIndicator} />
  </h4>
)

export default withStyles(styles)(LoadingMessage)
