import QAPVars from 'qap/vars'

export const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh'
  },
  main: {
    '@media print': {
      marginTop: theme.spacing(1) * (-5)
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    },
    minWidth: 0,
    overflow: 'auto',
    overflowScrolling: 'touch',
    minHeight: `calc(100vh - ${(theme.spacing(6)) + 20}px)`,
    display: 'block'
  },
  contentPadding: {
    padding: theme.spacing(3),
    '@media print': {
      padding: 0
    }
  },
  header: {
    zIndex: theme.zIndex.drawer + 1
  },
  toolbar: theme.mixins.toolbar,
  envBannerMargin: {
    marginTop: QAPVars.envBannerHeight
  },
  footer: {
    borderTop: `1px solid ${QAPVars.borderGray}`,
    padding: theme.spacing(3),
    position: 'sticky',
    bottom: 0,
    textAlign: 'right',
    '@media print': {
      display: 'none'
    }
  },
  poweredBy: {
    height: 20,
    width: 150
  },
  drawerOpen: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: QAPVars.sideNavBarWidth,
      transition: theme.transitions.create('paddingLeft', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    }
  },
  drawerClose: {
    [theme.breakpoints.up('lg')]: {
      transition: theme.transitions.create('paddingLeft', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: QAPVars.sideBarNavCollapsedWidth
    }
  },
  collapsedCommentPadding: {
    paddingRight: theme.spacing(9.25),
    transition: theme.transitions.create('paddingRight', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  expandedCommentPadding: {
    paddingRight: theme.spacing(55),
    transition: theme.transitions.create('paddingRight', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }
})
