// @flow
import React, {Component, Fragment} from 'react'
import classnames from 'classnames'
import {NavLink} from 'react-router-dom'
import {Drawer, Hidden, IconButton, ListItemIcon, ListItemText, MenuItem, Tooltip, withStyles} from '@material-ui/core'
import AnalyticsIcon from '@material-ui/icons/Timeline'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import DvrIcon from '@material-ui/icons/Dvr'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import InvoicesIcon from '@material-ui/icons/ReceiptOutlined'
import AddCommentIcon from '@material-ui/icons/AddComment'
import HomeIcon from '@material-ui/icons/Home'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import CardMembershipIcon from '@material-ui/icons/CardMembership'
import TodayIcon from '@material-ui/icons/Today'
import {PlaylistAddCheck} from "@material-ui/icons"

import ClassicPortalLink from 'components/organisms/classicPortalLink'
import ExternalLink from "components/atoms/externalLink"
import {IsPermitted} from 'components/molecules/isPermitted'
import Permission from 'components/molecules/permission'
import {
  ANALYTICS_PAGE_URL,
  CERTIFICATES_PAGE_URL,
  CLASSIC_PORTAL_ACCESS,
  DASHBOARD_PAGE_URL,
  DATA_ANALYSIS_ASSESSMENT_CRITERIA_URL,
  DATA_EXTRACT_PAGE_URL,
  ENV_BANNER_ENABLED,
  EXPERIMENTAL_FEATURE_ACCESS,
  REPORTS_PAGE_URL,
  ROUND_ENROLMENTS_PAGE_URL,
  SF_ENROLMENTS_TAB,
  SF_INVOICES_TAB,
  SF_REQUESTS_TAB
} from 'qap/constants'
import {API_ROUND_ENROLMENTS_INDEX} from 'state/roundEnrolments/actions'
import {API_REPORTS_INDEX} from 'state/reports/actions'
import {FETCH_CALENDAR_PREFERENCE} from 'state/calendarPreference/actions'
import {API_SALESFORCE_PORTAL_JWT_INDEX} from 'state/salesforcePortalTabs/actions'
import CorporationIcon from 'components/atoms/corporationIcon'
import QAPVars from 'qap/vars'
import {AuthContext} from 'contexts/auth'
import {showClassicPortalMenu, stringifyCalendarLink} from './helpers'

type Props = {
  openSidebar: boolean,
  onDrawerToggle: () => void,
  classes: Object
}

const styles = theme => ({
  menuItem: {
    marginTop: 8,
    paddingTop: 8,
    paddingBottom: 8,
    '&:hover': {
      backgroundColor: QAPVars.white20
    }
  },
  menuItemTextWrap:{
    alignItems: 'start',
    textWrap:'wrap',
  },
  selected: {
    backgroundColor: QAPVars.sBLight
  },
  drawerPaper: {
    width: QAPVars.sideNavBarWidth,
    backgroundColor: QAPVars.sBlue,
    top: ENV_BANNER_ENABLED ? QAPVars.envBannerHeight : 0
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    padding: '0 8px',
    ...theme.mixins.toolbar,
    [theme.breakpoints.down('md')]: {
      backgroundColor: QAPVars.white
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end'
    }
  },
  corporationIcon: {
    height: '3.4em',
    paddingLeft: '16px',
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  listIcon: {
    color: QAPVars.white70,
    width: theme.spacing(3)
  },
  chevron: {
    [theme.breakpoints.up('lg')]: {
      color: QAPVars.white70
    }
  },
  primary: {
    color: QAPVars.white,
    fontSize: '0.9375rem',
    textTransform: 'capitalize'
  },
  drawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: QAPVars.sideNavBarWidth,
      zIndex: theme.zIndex.appBar + 1,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    }
  },
  drawerClose: {
    [theme.breakpoints.up('md')]: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: QAPVars.sideBarNavCollapsedWidth
    }
  }
})

class SideBar extends Component <Props> {
  static contextType = AuthContext

  drawer = ({ collapsed }) => {
    const { classes, openSidebar, onDrawerToggle } = this.props
    const {
      user: { externalId },
      isFeatureActive,
      canViewAnalytics
    } = this.context

    const dashboardPageEnabled = isFeatureActive('dashboard')
    const salesforcePortalIntegrationEnabled = isFeatureActive('salesforce_portal_integration')
    const handleMenuClick = () => {
      if (collapsed && openSidebar) {
        onDrawerToggle()
      }
    }

    return (
      <Fragment>
        <div className={classes.drawerHeader}>
          <CorporationIcon className={classes.corporationIcon} />
          <Tooltip title={openSidebar ? 'Collapse the sidebar' : ''}>
            <IconButton
              color='secondary'
              onClick={onDrawerToggle}
            >
              <ChevronLeftIcon className={classes.chevron} />
            </IconButton>
          </Tooltip>
        </div>
        {
          dashboardPageEnabled &&
          <Permission include={EXPERIMENTAL_FEATURE_ACCESS.viewDashboard}>
            <Tooltip title={openSidebar ? '' : 'Dashboard'}>
              <MenuItem
                component={NavLink}
                to={DASHBOARD_PAGE_URL}
                activeClassName={classes.selected}
                className={classes.menuItem}
                onClick={handleMenuClick}
              >
                <ListItemIcon>
                  <HomeIcon className={classes.listIcon} />
                </ListItemIcon>
                <ListItemText
                  primary='Dashboard'
                  classes={{ primary: classes.primary }}
                />
              </MenuItem>
            </Tooltip>
          </Permission>
        }
        <IsPermitted action={API_REPORTS_INDEX}>
          <Tooltip title={openSidebar ? '' : 'Reports'}>
            <MenuItem
              component={NavLink}
              to={REPORTS_PAGE_URL}
              activeClassName={classes.selected}
              className={classes.menuItem}
              onClick={handleMenuClick}
            >
              <ListItemIcon>
                <MenuBookIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText
                primary='Reports'
                classes={{ primary: classes.primary }}
              />
            </MenuItem>
          </Tooltip>
        </IsPermitted>
        <IsPermitted action={API_ROUND_ENROLMENTS_INDEX}>
          <Tooltip title={openSidebar ? '' : 'Result Entry'}>
            <MenuItem
              component={NavLink}
              to={ROUND_ENROLMENTS_PAGE_URL}
              activeClassName={classes.selected}
              className={classes.menuItem}
              onClick={handleMenuClick}
            >
              <ListItemIcon>
                <DvrIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText
                primary='Result Entry'
                classes={{ primary: classes.primary }}
              />
            </MenuItem>
          </Tooltip>
        </IsPermitted>

        {salesforcePortalIntegrationEnabled &&
          <Fragment>
            <Permission include={CLASSIC_PORTAL_ACCESS.viewRequests}>
              <IsPermitted action={API_SALESFORCE_PORTAL_JWT_INDEX}>
                <Tooltip title={openSidebar ? '' : 'Requests'}>
                  <MenuItem
                    component={ClassicPortalLink}
                    redirectUrl={SF_REQUESTS_TAB}
                    className={classes.menuItem}
                    onClick={handleMenuClick}
                  >
                    <ListItemIcon>
                      <AddCommentIcon className={classes.listIcon} />
                    </ListItemIcon>
                    <ListItemText
                      primary='Requests'
                      classes={{ primary: classes.primary }}
                    />
                  </MenuItem>
                </Tooltip>
              </IsPermitted>
            </Permission>
            <Permission include={CLASSIC_PORTAL_ACCESS.viewEnrolments}>
              <IsPermitted action={API_SALESFORCE_PORTAL_JWT_INDEX}>
                <Tooltip title={openSidebar ? '' : 'Enrolments'}>
                  <MenuItem
                    component={ClassicPortalLink}
                    redirectUrl={SF_ENROLMENTS_TAB}
                    activeClassName={classes.selected}
                    className={classes.menuItem}
                    onClick={handleMenuClick}
                  >
                    <ListItemIcon>
                      <AddShoppingCartIcon className={classes.listIcon} />
                    </ListItemIcon>
                    <ListItemText
                      primary='Enrolments'
                      classes={{ primary: classes.primary }}
                    />
                  </MenuItem>
                </Tooltip>
              </IsPermitted>
            </Permission>
            <Permission include={CLASSIC_PORTAL_ACCESS.viewInvoices}>
              <IsPermitted action={API_SALESFORCE_PORTAL_JWT_INDEX}>
                <Tooltip title={openSidebar ? '' : 'Invoices'}>
                  <MenuItem
                    component={ClassicPortalLink}
                    redirectUrl={SF_INVOICES_TAB}
                    activeClassName={classes.selected}
                    className={classes.menuItem}
                    onClick={handleMenuClick}
                  >
                    <ListItemIcon>
                      <InvoicesIcon className={classes.listIcon} />
                    </ListItemIcon>
                    <ListItemText
                      primary='Invoices'
                      classes={{ primary: classes.primary }}
                    />
                  </MenuItem>
                </Tooltip>
              </IsPermitted>
            </Permission>
          </Fragment>
        }
         <IsPermitted action={API_REPORTS_INDEX}>
          <Tooltip title={openSidebar ? '' : 'Certificates'}>
            <MenuItem
              component={NavLink}
              to={CERTIFICATES_PAGE_URL}
              activeClassName={classes.selected}
              className={classes.menuItem}
              onClick={handleMenuClick}
            >
              <ListItemIcon>
                <CardMembershipIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText
                primary='Certificates'
                classes={{ primary: classes.primary }}
              />
            </MenuItem>
          </Tooltip>
        </IsPermitted>
        <IsPermitted action={FETCH_CALENDAR_PREFERENCE}>
          <Tooltip title={openSidebar ? '' : 'Program Calendar'}>
            <MenuItem
              component={NavLink}
              to={stringifyCalendarLink()}
              activeClassName={classes.selected}
              className={classes.menuItem}
              onClick={handleMenuClick}
            >
              <ListItemIcon>
                <TodayIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText
                primary='Program Calendar'
                classes={{ primary: classes.primary }}
              />
            </MenuItem>
          </Tooltip>
        </IsPermitted>
        {
          canViewAnalytics &&
          <Fragment>
            <Tooltip title={openSidebar ? '' : 'Analytics'}>
              <MenuItem
                component={NavLink}
                to={ANALYTICS_PAGE_URL}
                activeClassName={classes.selected}
                className={classes.menuItem}
                onClick={handleMenuClick}
              >
                <ListItemIcon>
                  <AnalyticsIcon className={classes.listIcon} />
                </ListItemIcon>
                <ListItemText
                  primary='Analytics'
                  classes={{ primary: classes.primary }}
                />
              </MenuItem>
            </Tooltip>
            <Tooltip title={openSidebar ? '' : 'Data Extract'}>
              <MenuItem
                component={NavLink}
                to={DATA_EXTRACT_PAGE_URL}
                activeClassName={classes.selected}
                className={classes.menuItem}
                onClick={handleMenuClick}
              >
                <ListItemIcon>
                  <DescriptionOutlinedIcon className={classes.listIcon} />
                </ListItemIcon>
                <ListItemText
                  primary='Data Extract'
                  classes={{ primary: classes.primary }}
                />
              </MenuItem>
            </Tooltip>
          </Fragment>
        }
        {salesforcePortalIntegrationEnabled &&
          <Fragment>
            {
              showClassicPortalMenu() &&
              <Permission include={CLASSIC_PORTAL_ACCESS.viewHome}>
                <IsPermitted action={API_SALESFORCE_PORTAL_JWT_INDEX}>
                  <Tooltip title={openSidebar ? '' : 'Classic Portal'}>
                    <MenuItem
                      component={ClassicPortalLink}
                      className={classes.menuItem}
                      onClick={handleMenuClick}
                    >
                      <ListItemIcon>
                        <ExitToAppIcon className={classes.listIcon} />
                      </ListItemIcon>
                      <ListItemText
                        primary='Classic Portal'
                        classes={{ primary: classes.primary }}
                      />
                    </MenuItem>
                  </Tooltip>
                </IsPermitted>
              </Permission>
            }
          </Fragment>
        }

        <Tooltip title={openSidebar ? '' : 'Data Analysis & Assessment Criteria'}>
          <MenuItem
            component={ExternalLink}
            to={DATA_ANALYSIS_ASSESSMENT_CRITERIA_URL}
            activeClassName={classes.selected}
            className={classnames(classes.menuItem, {[classes.menuItemTextWrap]: Boolean(openSidebar)})}
            onClick={handleMenuClick}
          >
            <ListItemIcon>
              <PlaylistAddCheck className={classes.listIcon} />
            </ListItemIcon>
            <ListItemText
              primary='Data Analysis & Assessment Criteria'
              classes={{ primary: classes.primary }}
            />
          </MenuItem>
        </Tooltip>

      </Fragment>
    )
  }
  render () {
    const { classes, openSidebar, onDrawerToggle } = this.props

    return (
      <Fragment>
        <Hidden lgUp>
          <Drawer
            variant='temporary'
            open={openSidebar}
            onClose={onDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true
            }}
          >
            {this.drawer({ collapsed: true })}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation='css'>
          <Drawer
            variant='permanent'
            className={classnames({
              [classes.drawerOpen]: openSidebar,
              [classes.drawerClose]: !openSidebar
            })}
            classes={{
              paper: classnames(
                classes.drawerPaper, {
                  [classes.drawerOpen]: openSidebar,
                  [classes.drawerClose]: !openSidebar
                }
              )
            }}
          >
            {this.drawer({ collapsed: false })}
          </Drawer>
        </Hidden>
      </Fragment>
    )
  }
}

export default withStyles(styles)(SideBar)
