// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core'

import InPageAlert from 'components/atoms/inPageAlert'

export const useStyles = makeStyles(theme => ({
  alertRoot: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  alertTitleText: {
    ...theme.typography.h6,
  },
}))

const PermissionErrorMessage = () => {
  const classes = useStyles()

  return (
    <InPageAlert
      title={"You don't have permission to view this content."}
      variant='outlined'
      icon={false}
      classes={classes}
    />
  )
}

export default PermissionErrorMessage
