// @flow
import React, { createContext, forwardRef, useContext } from 'react'
import { Fade, Tooltip, makeStyles } from '@material-ui/core'
// $FlowFixMe
import { FixedSizeList } from 'react-window'
import QAPVars from 'qap/vars'

type Props = {
  children: Object[]
}

const LISTBOX_PADDING = 8 // px
const ITEM_SIZE = 43 // px

const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: QAPVars.pGray,
    fontSize: '0.87rem',
  },
}))

const StyledTooltip = ({ title='', children, ...props }) => {
  const classes = useStyles()

  return (
    <Tooltip
      title={title}
      placement='right'
      classes={classes}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 300 }}
      enterTouchDelay={0}
      {...props}
    >
      {children}
    </Tooltip>
  )
}

const renderRow = (props) => {
  const { data, index, style } = props
  const element = data[index]
  const tooltipText = element.props.children

  return (
    <StyledTooltip title={tooltipText}>
      {
        React.cloneElement(element, {
          style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }
        })
      }
    </StyledTooltip>
  )
}

const OuterElementContext = createContext({})

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

// Adapter for react-window
export const ListboxComponent = forwardRef<Props, window.HTMLDivElement>((props, ref) => {
  const { children, ...other } = props

  const itemData = React.Children.toArray(children)

  const itemCount = itemData.length

  const getChildSize = child => ITEM_SIZE

  const getHeight = () => {
    if (itemCount > 8) return 8 * ITEM_SIZE

    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          key={itemCount}
          outerElementType={OuterElementType}
          overscanCount={5}
          itemCount={itemCount}
          itemSize={ITEM_SIZE}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})
