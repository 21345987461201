
import { createTheme } from '@material-ui/core'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import QAPVars from 'qap/vars'

const headingFontFamily = 'HelveticaNeue-Medium, Arial, sans-serif'
const mediumFontFamily = 'HelveticaNeue-Medium, Arial, sans-serif'
const regularFontFamily = 'HelveticaNeue, Arial, sans-serif'
const lightFontFamily = 'HelveticaNeue-Light, Arial, sans-serif'

const breakpointValues = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1600,
    xl: 1920
  }
}

const breakpoints = createBreakpoints(breakpointValues)

const print = {
  '@media print': {
    color: QAPVars.primaryDark
  }
}

const theme = createTheme({
  breakpoints: breakpointValues,
  typography: {
    fontFamily: regularFontFamily,
    mediumFontFamily,
    h5: {
      fontFamily: headingFontFamily,
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 1.16,
      letterSpacing: 0.4,
      color: QAPVars.sBlue,
      ...print
    },
    h6: {
      fontFamily: lightFontFamily,
      fontSize: '1.25rem',
      fontWeight: 300,
      lineHeight: 1.5,
      letterSpacing: 0.8,
      [breakpoints.up('md')]: {
        fontSize: '1.5rem',
        lineHeight: 1.2
      },
      ...print
    },
    h4: {
      fontFamily: lightFontFamily,
      fontSize: '1rem',
      fontWeight: 300,
      lineHeight: 1.24,
      letterSpacing: 0.8,
      ...print
    },
    h3: {
      fontFamily: regularFontFamily,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.24,
      letterSpacing: 0.6,
      ...print
    },
    h2: {
      fontFamily: mediumFontFamily,
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.32,
      letterSpacing: 0.6,
      color: QAPVars.copyGray,
      ...print
    },
    h1: {
      fontFamily: mediumFontFamily,
      fontSize: '1.25rem',
      fontWeight: 500,
      letterSpacing: 0.4,
      color: QAPVars.copyGray,
      ...print
    },
    subtitle1: {
      fontFamily: lightFontFamily,
      fontSize: '1rem',
      fontWeight: 300,
      lineHeight: 1.24,
      letterSpacing: 0.8,
      color: QAPVars.copyGray,
      ...print
    },
    label1: {
      fontFamily: mediumFontFamily,
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.27,
      letterSpacing: 0.8,
      ...print
    },
    body1: {
      fontFamily: regularFontFamily,
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.27,
      letterSpacing: 0.4,
      ...print
    },
    tableRowImportant: {
      fontFamily: mediumFontFamily,
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: 1.27,
      letterSpacing: 0.4,
      ...print
    },
    caption: {
      fontFamily: regularFontFamily,
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.32,
      letterSpacing: 0.4,
      ...print
    },
    caption2: {
      fontFamily: lightFontFamily,
      fontSize: '0.75rem',
      fontWeight: 300,
      lineHeight: 1.32,
      letterSpacing: 0.6,
      ...print
    },
    button: {
      fontFamily: regularFontFamily,
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.27,
      letterSpacing: 0.4
    }
  },
  palette: {
    primary: {
      main: QAPVars.hero
    },
    secondary: {
      main: QAPVars.hero
    },
    error: {
      main: QAPVars.sRed
    },
    info: {
      main: QAPVars.white
    }
  },
  zIndex: {
    drawer: 1000
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: QAPVars.white
      }
    },
    MuiAvatar: {
      root: {
        fontFamily: lightFontFamily,
        fontSize: '0.875rem',
        letterSpacing: 0.8
      }
    },
    MuiBadge: {
      root: {
        padding: '0 20px'
      },
      badge: {
        backgroundColor: 'rgba(45, 55, 62, 0.12)',
        borderRadius: '20px',
        width: '1.81rem',
        height: '1.375rem',
        color: QAPVars.pGray,
        fontFamily: regularFontFamily,
        fontSize: '0.75rem',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1,
        letterSpacing: 'normal'
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        color: QAPVars.copyGray,
        borderRadius: 2,
        paddingLeft: 24,
        paddingRight: 24
      },
      contained: {
        backgroundColor: '#f5f5f5',
        '&:hover': {
          backgroundColor: QAPVars.white92
        }
      }
    },
    MuiCheckbox: {
      root: {
        '&:hover': {
          backgroundColor: QAPVars.buttonHover
        }
      }
    },
    MuiRadio: {
      root: {
        '&:hover': {
          backgroundColor: QAPVars.buttonHover
        }
      }
    },
    MuiDialogContentText: {
      root: {
        fontFamily: regularFontFamily,
        color: QAPVars.copyGray,
        fontSize: '0.875rem'
      }
    },
    MuiDialogActions: {
      root: {
        margin: '0 24px 24px 24px',
        '& button:not(:first-child)': {
          marginLeft: 16
        },
        '& button:last-child': {
          marginRight: 0
        }
      }
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: QAPVars.copyGray
        }
      }
    },
    MuiInput: {
      input: {
        fontFamily: lightFontFamily,
        color: QAPVars.copyGray,
        letterSpacing: 0.8
      },
      underline: {
        '&$focused:after': {
          borderBottomColor: QAPVars.linkBlue
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottomColor: QAPVars.linkBlue
        }
      }
    },
    MuiListItem: {
      root: {
        paddingTop: 16,
        paddingBottom: 16
      }
    },
    MuiListItemIcon: {
      root: {
        color: 'rgba(0, 0, 0, 0.7)'
      }
    },
    MuiListItemText: {
      dense: {
        fontSize: '0.875rem'
      }
    },
    MuiMenuItem: {
      root: {
        color: QAPVars.black,
        '&$selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.14)'
          }
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: QAPVars.copyGray
        }
      }
    },
    MuiTab: {
      root: {
        '&:hover': {
          backgroundColor: alpha(QAPVars.hero, 0.12)
        },
        '&:selected': {
          color: QAPVars.hero
        }
      },
      wrapper: {
        fontFamily: mediumFontFamily,
        fontSize: '0.9375rem',
        letterSpacing: 0.6
      },
      textColorInherit: {
        color: alpha(QAPVars.copyGray, 0.82),
        opacity: 1
      }
    },
    MuiTableCell: {
      root: {
        padding: 12,
        lineHeight: 1.5
      },
      head: {
        fontFamily: mediumFontFamily,
        fontSize: '0.875rem',
        color: QAPVars.pGray,
        letterSpacing: 0.8
      },
      body: {
        fontFamily: lightFontFamily,
        fontSize: '0.875rem',
        color: QAPVars.copyGray,
        letterSpacing: 0.8
      }
    },
    MuiTablePagination: {
      caption: {
        fontSize: '0.875rem'
      }
    },
    MuiTooltip: {
      tooltip: {
        color: QAPVars.white,
        fontFamily: lightFontFamily,
        fontSize: '0.75rem',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.33,
        letterSpacing: '0.025rem',
        backgroundColor: QAPVars.copyGray
      }
    },
    MuiAccordion: {
      root: {
        backgroundColor: QAPVars.white,
        boxShadow: 'none',
        border: `1px solid ${QAPVars.borderGray}`,
        '&::before': {
          display: 'none'
        }
      }
    },
    MuiFormHelperText: {
      root: {
        lineHeight: '1.3em',
        letterSpacing: '0.4px',
        paddingBottom: 8
      }
    },
    MuiSnackbarContent: {
      root: {
        flexWrap: 'nowrap'
      },
      message: {
        '& a': {
          color: QAPVars.linkBlueLight,
          cursor: 'pointer'
        },
        '& a:hover': {
          color: QAPVars.linkBlueLight,
          opacity: 0.8,
          textDecoration: 'underline'
        }
      },
      action: {
        opacity: 0.54
      }
    },
    MuiIconButton: {
      root: {
        width: 40,
        height: 40,
        padding: 0,
        lineHeight: 0
      }
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 'auto'
      }
    },
    MuiAutocomplete: {
      endAdornment: {
        top: 'calc(50% - 18px)',
        right: 9,
        position: 'absolute'
      }
    },
    MuiAlert: {
      filledInfo: {
        backgroundColor: QAPVars.hero
      }
    }
  }
})

export default theme
