// @flow
import React from 'react'
import { withStyles, Grid, Typography } from '@material-ui/core'

import Avatar from 'components/molecules/avatar'

import QAPVars from 'qap/vars'
import type { User } from 'types'

type UserInfoProps = {
  classes: Object,
  user: User
}

const styles = theme => ({
  avatar: {
    paddingRight: theme.spacing(1)
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  name: {
    fontWeight: 'bold'
  },
  email: {
    color: QAPVars.pdfBGGray
  }
})

const UserInfo = ({ classes, user } : UserInfoProps) => {
  const { firstName = '', lastName = '', email = '' } = user
  const userName = `${firstName} ${lastName}`

  return (
    <Grid container>
      <Grid item className={classes.avatar}>
        <Avatar user={user} />
      </Grid>
      <Grid item className={classes.textContainer}>
        <Typography className={classes.name}>{ userName }</Typography>
        <Typography className={classes.email}>{ email }</Typography>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(UserInfo)
