// @flow
import React, { Component } from 'react'
import jQuery from 'jquery'
import { darken } from '@material-ui/core/styles/colorManipulator'
import { withStyles, Button } from '@material-ui/core'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
import { JIRA_COLLECTOR_URL } from 'qap/constants'

import QAPVars from 'qap/vars'

type Props = {
  classes: Object
}

const styles = theme => ({
  root: {
    position: 'fixed',
    bottom: 85,
    '@media (min-height: 800px)': {
      bottom: '15vh'
    },
    right: 0,
    backgroundColor: QAPVars.coolGray,
    color: QAPVars.white,
    zIndex: 1,
    minWidth: 0,
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: darken(QAPVars.coolGray, 0.15)
    },
    '@media print': {
      display: 'none'
    }
  },
  icon: {
    fontSize: 22,
    transform: 'scaleX(-1)'
  }
})

class Feedback extends Component<Props> {
  showDialog = null

  componentDidMount () {
    if (JIRA_COLLECTOR_URL) {
      window.jQuery = jQuery

      window.ATL_JQ_PAGE_PROPS = {
        triggerFunction: (showDialog) => {
          this.showDialog = showDialog
        }
      }

      jQuery.ajax({
        url: JIRA_COLLECTOR_URL,
        type: 'get',
        cache: true,
        dataType: 'script'
      })
    }
  }

  handleClick = () => {
    this.showDialog && this.showDialog()
  }

  render () {
    if (!JIRA_COLLECTOR_URL) return null

    const { classes } = this.props
    return (
      <Button
        id='feedback-button'
        className={classes.root}
        onClick={this.handleClick}
        title='Feedback'
      >
        <ChatBubbleOutlineIcon className={classes.icon} />
      </Button>
    )
  }
}

export default withStyles(styles)(Feedback)
