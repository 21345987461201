// @flow
import React from 'react'
import { withStyles } from '@material-ui/core'
import { styles } from 'components/atoms/link'
import type { Node as ReactNode } from 'react'

type Props = {
  to: string,
  target: string,
  classes: Object,
  children: Array<ReactNode> | ReactNode
}

const ExternalLink = React.forwardRef((props: Props, ref) => {
  const { to, classes, target = '_blank', ...linkProps } = props
  return (
    <a
      ref={ref}
      className={classes.root}
      href={to}
      target={target}
      rel='nofollow noreferrer noopener'
      {...linkProps}
    >{linkProps.children}</a>
  )
})

export default withStyles(styles)(ExternalLink)
