import React, { useState } from 'react'
import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Switch,
  FormControlLabel
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

type Props = {
  acceptCookies: () => void,
  open: boolean,
  submitting: boolean
}

const useStyles = makeStyles(theme => ({
  functionalSummary: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  functionalSummaryText: {
    flexGrow: '1'
  }
}))

const AcceptingCookiesModal = ({ acceptCookies, open, submitting }: Props) => {
  const classes = useStyles()
  const [checked, setChecked] = useState(true)

  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText>
          Please choose whether this site may use cookies or related
            technologies as described below.
        </DialogContentText>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            Required Cookies
          </AccordionSummary>
          <AccordionDetails>
            These cookies are necessary to enable basic features of the site to
            function, such as providing secure login.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.functionalSummary}>
              <Typography
                className={classes.functionalSummaryText}
                component='div'
              >
                Functional Cookies
              </Typography>
              <FormControlLabel
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <Switch
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                  />
                }
              />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            These cookies allow us to analyse your use of the site to evaluate
            and improve our performance by providing information about how the
            site is being used.
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          type='submit'
          onClick={() => acceptCookies(checked)}
          disabled={submitting}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AcceptingCookiesModal
