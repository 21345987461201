// @flow
import React, { Fragment, useState, useContext } from 'react'
import { withStyles, Menu, MenuItem, Divider } from '@material-ui/core'
import classnames from 'classnames'

import { AuthContext } from 'contexts/auth'
import { Permissions } from 'api'
import { FETCH_CALENDAR_PREFERENCE } from 'state/calendarPreference/actions'

import Link from 'components/atoms/link'
import Avatar from 'components/molecules/avatar'
import SwitchOrganisationButton from '../switchOrganisationButton'
import UserInfo from './userInfo'

import QAPVars from 'qap/vars'
import {
  LANDING_PAGE_URL,
  USER_CALENDAR_PREFERENCE_URL,
  USER_EMAIL_PREFERENCE_URL
} from 'qap/constants'

import SwitchOrganisation from '../switchOrganisation'

import type { ElementRef } from 'react'

const styles = theme => ({
  avatar: {
    cursor: 'pointer'
  },
  menu: {
    zIndex: theme.zIndex.tooltip + 1
  },
  displayContainer: {
    cursor: 'default',
    pointerEvents: 'none'
  },
  divider: {
    borderTop: `solid 2px ${QAPVars.pdfHeaderIconGray}`,
    marginTop: theme.spacing(1)
  }
})

type MenuProps = {
  classes: Object,
  menuRef: ElementRef<any>
}

const CurrentUserMenu = ({ classes, menuRef }: MenuProps) => {
  const [open, setOpen] = useState(false)
  const [displayOrg, setDisplayOrg] = useState(false)
  const {
    user,
    logoutUser,
    canSwitchOrganisations,
    selectedOrganisations = [],
    isFeatureActive,
    authorization
  } = useContext(AuthContext)
  const canSubscribeCalendar = Permissions.isOperationPermitted(authorization, FETCH_CALENDAR_PREFERENCE)
  const userPreferenceUrl = canSubscribeCalendar && isFeatureActive('calendar')
    ? USER_CALENDAR_PREFERENCE_URL : USER_EMAIL_PREFERENCE_URL

  const toggle = () => {
    setOpen(!open)
  }

  const toggleOrgSwitcher = () => {
    setOpen(false)
    setDisplayOrg(!displayOrg)
  }

  return (
    <Fragment>
      <SwitchOrganisationButton
        selectedOrganisations={selectedOrganisations}
        toggleOrgSwitcher={toggleOrgSwitcher}
      />

      <div id='notification-config' ref={menuRef}>
        <Avatar
          className={classes.avatar}
          onClick={toggle}
          user={user}
        />
      </div>

      {
        displayOrg && canSwitchOrganisations &&
        <SwitchOrganisation onClose={toggleOrgSwitcher} />
      }

      <Menu
        anchorEl={menuRef.current}
        open={Boolean(open)}
        onClose={toggle}
        PopoverClasses={{ root: classes.menu }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem className={classes.displayContainer}>
          <UserInfo user={user} />
        </MenuItem>
        <MenuItem className={classnames(classes.divider, classes.displayContainer)}>
          <Divider />
        </MenuItem>
        {
          canSwitchOrganisations &&
          <MenuItem onClick={toggleOrgSwitcher}>
            Switch organisation
          </MenuItem>
        }
        <Link
          to={userPreferenceUrl}
          onClick={() => setOpen(false)}
          hideUnderline
        >
          <MenuItem>
            Preferences
          </MenuItem>
        </Link>
        <Link
          to={LANDING_PAGE_URL}
          onClick={logoutUser}
          hideUnderline
        >
          <MenuItem>
            Logout
          </MenuItem>
        </Link>
      </Menu>
    </Fragment>
  )
}

export default withStyles(styles)(CurrentUserMenu)
