// @flow
import React from 'react'
import { Tooltip as MaterialToolTip } from '@material-ui/core'

export const ToolTip = (props: Object) => (
  <MaterialToolTip
    placement='bottom'
    leaveDelay={1500}
    {...props}
  />
)

export default ToolTip
