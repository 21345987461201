// @flow
import React from 'react'
import { withStyles, Typography } from '@material-ui/core'
import { DisplayStackTrace } from 'components/molecules/errorBoundary'
import QAPVars from 'qap/vars'

import type { Theme } from '@material-ui/core/styles/createTheme'
import type { ErrorComponentInterface } from 'components/molecules/errorBoundary'

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing(5),
    fontFamily: theme.typography.mediumFontFamily
  },
  link: {
    color: QAPVars.linkBlue,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
})

const GlobalErrorBoundary = ({ classes, host, errors }: ErrorComponentInterface) => {
  let title = 'Something has gone wrong'
  let description = (
    <div>
      The development team has been notified of the issue.
      Try <a href='.' className={classes.link}>refreshing the page</a>.
    </div>
  )

  if (errors.name === 'ChunkLoadError') {
    setTimeout(() => window.location.reload(), 2000)

    title = 'An Update is Available'
    description = (
      <div>
        A new version of myQAP has been released.
        Please <a href='.' className={classes.link}>refresh the page</a>.
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Typography variant='h1'>{title}</Typography>
      {description}

      <DisplayStackTrace host={host} errors={errors} />
    </div>
  )
}

export default withStyles(styles)(GlobalErrorBoundary)
