// @flow
import React, { useContext } from 'react'
import { Tabs, Tab } from '@material-ui/core'
import { Link } from 'react-router-dom'
import qs from 'qs'

import { AuthContext } from 'contexts/auth'
import { Permissions } from 'api'
import { FETCH_CALENDAR_PREFERENCE } from 'state/calendarPreference/actions'
import { FETCH_FOLLOW_UP_LABELS } from 'state/customFollowUpLabels/actions'

type Props = {
  currentTab: string
}

type TabConfig = {
  label: string,
  value: string,
  featureToggleKey?: string,
  permission?: string
}

export const TABS = [
  {
    label: 'Program Calendar',
    value: 'calendar-subscriptions',
    featureToggleKey: 'calendar',
    permission: FETCH_CALENDAR_PREFERENCE
  },
  {
    label: 'Custom Follow-up Labels',
    value: 'custom-follow-up-labels',
    permission: FETCH_FOLLOW_UP_LABELS
  },
  {
    label: 'Email Notifications',
    value: 'email-notifications'
  },
  {
    label: 'General',
    value: 'general'
  },
  {
    label: 'Account',
    value: 'accounts'
  },
]

const UserPreferenceTabs = ({ currentTab }: Props) => {
  const query = qs.parse(window.location.search.substring(1))
  const tabFilter = (query && query.tabFilter) ? query.tabFilter.split() : undefined
  const { isFeatureActive, authorization } = useContext(AuthContext)
  const enabledTabs = TABS.filter(
    ({ featureToggleKey, permission = '', value }: TabConfig) => {
      if (Array.isArray(tabFilter) && !tabFilter.includes(value)) {
        return false
      }

      if (!featureToggleKey && !permission) {
        return true
      }

      if (featureToggleKey && !isFeatureActive(featureToggleKey)) return false
      if (Permissions.isOperationPermitted(authorization, permission)) return true

      return false
    }
  )

  return (
    <Tabs indicatorColor='primary' value={currentTab}>
      {
        enabledTabs.map(
          ({ label, value }) => (
            <Tab
              key={value}
              value={value}
              label={label}
              component={Link}
              to={`/preferences/${value}`}
            />
          )
        )
      }
    </Tabs>
  )
}

export default UserPreferenceTabs
